
export default {
    data() {
        return {
            carPartFlag: false,
            carPartLoading: false,
            provinceVal: '',
            provinceOptions: [],
            searchForm: {
                condition: {
                    brand: '',
                    productId: '',
                    productName: '',
                    productType: '',
                    sparePartCategory: '',
                    sparePartName: ''
                },
                // condition: {
                //     brand: 'apple',
                //     // productId: 'apple_pricing_airpods_max',
                //     productId: '',
                //     productName: '',
                //     productType: 'airpods',
                //     sparePartCategory: '',
                //     sparePartName: ''
                // },
                orders: [],
                pageNo: 1,
                pageSize: 10,
                pageTotal: 0
            },
            resultList: [],
            searchInfoForm: {
                oeOrPartName: ''
            },
            structureList: [],
            selectedParts: [],
            yearOptions: [],
            partPrice: null,
            brandsOptions: [],
            productTypeOptions: [],
            modulesOptions: [],
            selectTableObj: {},
            selectPartRowObj: {},
            searchStr: '',
            partPrice: null,
            searchPartFlag: false
        };
    },
    mounted() {
        this.queryProductBrands();
        // setTimeout(() => {
        //     this.searchData();
        // }, 1000);
    },
    methods: {
        queryProductBrands() {
            this.brandsOptions = [];
            this.instance.get('/tpa/api/product/brands', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.brandsOptions = data.data;
                }
            });
        },
        queryProductTypes() {
            this.productTypeOptions = [];
            this.modulesOptions = [];
            this.searchForm.condition.productType = '';
            this.searchForm.condition.productId = '';
            if (!this.searchForm.condition.brand) {
                return;
            }
            this.instance.get('/tpa/api/product/types', { params: { brand: this.searchForm.condition.brand } }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.productTypeOptions = data.data;
                }
            });
        },
        queryProductModules() {
            this.modulesOptions = [];
            this.searchForm.condition.productId = '';
            if (!this.searchForm.condition.productType) {
                return;
            }
            this.instance.post('tpa/api/product/modules', this.searchForm.condition).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.modulesOptions = data.data;
                }
            });
        },
        showCarDialog() {
            this.$emit('queryGoodsShow', { partType: 2, closeType: 1 });
        },
        searchData(resetPageNo) {
            if (!this.searchForm.condition.brand) {
                this.$message.error('请选择品牌！');
                return;
            }

            if (resetPageNo) {
                this.searchForm.pageNo = 1;
            }

            this.queryProductSparePart();
        },
        queryProductSparePart() {
            this.resultList = [];
            this.searchStr = this.searchForm.condition.brand + '-家电配件分割线-' + this.searchForm.condition.productType + '-家电配件分割线-' + this.searchForm.condition.productId + '-家电配件分割线-' + this.searchForm.condition.sparePartName + '-家电配件分割线-' + this.searchForm.pageNo;
            this.searchPartFlag = true;
            this.instance.post('/tpa/api/product/spare_part/pages', this.searchForm).then((res) => {
                this.searchPartFlag = false;
                let data = res.data;
                if (data.code == 200) {
                    this.resultList = data.data.list;
                    this.searchForm.pageTotal = data.data.total || 0;
                    this.selectTableData(this.resultList);
                }
            });
        },
        // 回显选择数据
        selectTableData(resultTableData) {
            let tmpSelectObj = JSON.parse(JSON.stringify(this.selectPartRowObj));
            let setSelectArr = [];
            resultTableData.forEach((tableItem) => {
                if (tmpSelectObj[tableItem.id]) {
                    setSelectArr.push(tableItem);
                }
            });

            setTimeout(() => {
                this.toggleSelection(setSelectArr, true);
            }, 200);
        },
        handleCurrentChange(val) {
            this.searchForm.pageNo = val;
            this.searchData();
        },
        handleSizeChange(val) {
            this.searchForm.pageSize = val;
            this.searchData();
        },
        handleSelectionChange(val) {
            let delSelectData = [];
            this.resultList.forEach((tableItem) => {
                if (this.selectPartRowObj[tableItem.id]) {
                    delSelectData.push(tableItem);
                    delete this.selectPartRowObj[tableItem.id];
                }
            });

            if (val.length > 0) {
                val.forEach((rowItem) => {
                    this.selectPartRowObj[rowItem.id] = rowItem;
                });
            } else {
                setTimeout(() => {
                    this.toggleSelection(delSelectData, false);
                }, 200);
            }

            this.calculatePrice();
        },
        toggleSelection(rows, selected) {
            if (rows) {
                rows.forEach((row) => {
                    this.$refs.partTableRef.toggleRowSelection(row, selected);
                });
            } else {
                this.$refs.partTableRef.clearSelection();
            }
        },
        calculatePrice() {
            let tmpPrice = 0;

            this.selectedParts = Object.values(this.selectPartRowObj);

            this.selectedParts.forEach((priceItem) => {
                if (priceItem.sparePartPrice) {
                    tmpPrice += Number(priceItem.sparePartPrice) * 100000;
                }
            });

            if (tmpPrice >= 0) {
                this.partPrice = tmpPrice / 100000;
            }
        },
        usePartPrice() {
            if (this.selectedParts.length < 1) {
                this.$message.error('请选择配件！');
                return;
            }
            this.$emit('queryGoodsShow', { partType: 2, closeType: 2 });
            this.$emit('setLossUnitPrice', this.partPrice);
        }
    }
};
