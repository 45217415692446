
import ImgDeal from '@/components/ImgDeal.vue';
export default {
    name: 'JdPay',
    props: {
        caseDetail: {
            typeof: Object,
            default: {}
        },
        tmpOSSClient: {
            type: Object,
            default: null
        },
        elementShow: {
            typeof: Boolean,
            default: false
        }
    },
    components: {
        ImgDeal
    },
    data() {
        return {
            suspendFlag: false,
            activeName: 'first',
            negotiateInfo: [
                {
                    accCause: '',
                    accProcess: '',
                    caseNo: '',
                    claimAmount: 0,
                    insuranceNo: '',
                    materialsVos: [
                        {
                            materialName: '',
                            materialOriginalPath: '',
                            materialParentType: 0,
                            materialPath: '',
                            materialUrl: '',
                            materialType: 0,
                            similarCaseNoList: [],
                            repeatCaseNos: []
                        }
                    ],
                    negotiateClaimAmount: 0,
                    packageNo: '',
                    waybillNo: ''
                }
            ],
            OSSClient: '',
            caseMaterialsParentType: [],
            caseMaterialsType: [],
            liActiveIndex: 0,
            negotiateActiveIndex: 0,
            imgUrlList: [],
            tmpImgUrlList: [],
            imgActiveIndex: 0, // 当前移动图片的索引值
            imgDistance: 0, // 移动的距离
            allDistance: 0, // 总移动距离
            imageUrl: '',
            previewShow: false,
            showCaseList: [
                {
                    enumList: [],
                    files: [],
                    parentCode: 0,
                    parentName: ''
                }
            ],
            videoShow: false,
            imgDialogVisible: false,
            previewUrl: '',
            subsetIndex: 0,
            titleIndex: 1,
            thumbnailActiveIndex: 0,
            tmpPreviewIndex: 0,
            initialIndex: 0,
            videoTypeList: ['mp4', 'ogg', 'mov', 'rmvb', 'rm', 'FLV', '3GP', 'webm'],
            videoShowData: {},
            tmpAccCause: '',
            tmpAccProcess: '',
            txtHoverStyleObj: {
                flag0: false,
                flag1: false
            },
            showTxtFlag: false,
            currentPreviewData: {}
        };
    },
    mounted() {
        this.getFileTypeList();
    },
    computed: {
        imgStyle() {
            return {
                transform: `translate3d(${this.imgDistance}px, 0, 0)` // 计算移动的距离(x,y,z)
            };
        }
    },
    methods: {
        showAccProcess() {
            this.showTxtFlag = !this.showTxtFlag;
        },
        previewPrev() {
            if (this.tmpPreviewIndex <= 0) {
                // 切换右侧分类
                if (this.thumbnailActiveIndex - 1 < 0) {
                    let tmpIndex = this.showCaseList[0].files ? this.showCaseList[0].files.length - 1 : 0;
                    this.subsetChange(null, tmpIndex, this.showCaseList[this.showCaseList.length - 1], this.showCaseList.length - 1);
                } else {
                    let tmpIndex = this.showCaseList[this.thumbnailActiveIndex - 1].files ? this.showCaseList[this.thumbnailActiveIndex - 1].files.length - 1 : 0;
                    this.subsetChange(null, tmpIndex, this.showCaseList[this.thumbnailActiveIndex - 1], this.thumbnailActiveIndex - 1);
                }
            } else {
                this.$refs.carouselEl.prev();
            }
        },
        previewNext() {
            if (this.tmpPreviewIndex == this.imgUrlList.length - 1) {
                // 切换右侧分类
                if (this.thumbnailActiveIndex + 1 >= this.showCaseListRealityLength) {
                    this.subsetChange(null, 0, this.showCaseList[0], 0);
                } else {
                    this.subsetChange(null, 0, this.showCaseList[this.thumbnailActiveIndex + 1], this.thumbnailActiveIndex + 1);
                }
            } else {
                this.$refs.carouselEl.next();
            }
        },
        subsetChange(items, itemsIndex, item, index) {
            this.subsetIndex = itemsIndex;
            this.thumbnailActiveIndex = index;
            this.switchCarousel(item, index);
            this.setCurrentData(itemsIndex);
            this.initialIndex = itemsIndex;
            this.$refs.carouselEl && this.$refs.carouselEl.setActiveItem(itemsIndex);
        },
        carouselChange(rowIndex) {
            this.titleIndex = rowIndex + 1;
            this.subsetIndex = rowIndex;
            // 当前图片index
            this.tmpPreviewIndex = rowIndex;
            this.setCurrentData(rowIndex);
        },
        closeImgDialog() {
            this.imgDialogVisible = false;
        },
        previewImg(src, i) {
            this.previewUrl = src;
            this.imgActiveIndex = i;
            this.imgDialogVisible = true;
            this.subsetIndex = i;
            this.tmpPreviewIndex = i;

            this.initialIndex = i;
            this.$refs.carouselEl && this.$refs.carouselEl.setActiveItem(i);
            this.setCurrentData();
        },
        changeImg(item, index) {
            this.imgActiveIndex = index;
            this.imageUrl = item;
            this.previewShow = true;
        },
        showVideo(item) {
            this.videoShowData = item;
            this.videoShow = true;
        },

        imgLeft() {
            if (this.imgActiveIndex > 0) {
                this.imgActiveIndex--; // 索引值-1
            }
            if (this.imgActiveIndex >= 4) {
                var index = 0;
                const temp = window.setInterval(() => {
                    // 利用定时器实现图片左右移动的动画效果
                    if (index < 33) {
                        // 移动次数(33次)
                        this.imgDistance += 2; // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
                        index++;
                        return;
                    } else {
                        window.clearInterval(temp); // 移动完清除定时器
                    }
                }, 10);
            }
        },
        imgRight() {
            if (this.imgActiveIndex < this.imgUrlList.length - 1) {
                this.allDistance = 0;
                this.imgActiveIndex++;
                if (this.imgActiveIndex >= 5) {
                    this.allDistance = -66 * (this.imgActiveIndex - 4);
                    var index = 0;
                    const temp = window.setInterval(() => {
                        if (index < 33) {
                            this.imgDistance -= 2; // 每次向右移动的距离
                            index++;
                            return;
                        } else {
                            window.clearInterval(temp);
                        }
                    }, 10);
                }
            } else if (this.imgActiveIndex === this.imgUrlList.length - 1) {
                // 到达最后一张图片，再点击跳转回第一张
                this.imgActiveIndex = 0;
                var index = 0;
                const temp = window.setInterval(() => {
                    // 利用定时器实现图片左右移动的动画效果
                    if (index < Math.abs(this.allDistance / 2)) {
                        // 取绝对值再除
                        this.imgDistance += 2; // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
                        index++;
                        return;
                    } else {
                        window.clearInterval(temp); // 移动完清除定时器
                    }
                }, 1);
            }
        },
        // 京东谈赔接口
        getNegotiateInfo() {
            this.instance.post('/tpa/api/case/negotiateList/' + this.caseDetail.caseNo).then((res) => {
                let data = res.data;
                if (data.code == 200 && data.data) {
                    this.negotiateInfo = data.data;

                    // 校验首张图片是否正常加载，决定是否重置OSSClient
                    if (this.negotiateInfo && this.negotiateInfo.length > 0) {
                        let tmpData = this.negotiateInfo[this.negotiateInfo.length - 1];
                        if (tmpData && tmpData.materialsVos.length > 0) {
                            this.checkImgUrl(this.OSSClient.signatureUrl(tmpData.materialsVos[0].materialPath));
                        }
                    }
                    this.switchNegotiate(this.negotiateInfo[this.negotiateInfo.length - 1], this.negotiateInfo.length - 1);
                }
            });
        },
        //获取所有的材料类型
        getFileTypeList() {
            this.instance.get('/tpa/api/enum/getCaseMaterialsTypeEnum', {}).then((res) => {
                if (res.data.code === 200) {
                    this.caseMaterialsParentType = res.data.data;
                }
            });
        },
        switchCarousel(data, index) {
            this.imgActiveIndex = 0;
            this.liActiveIndex = index;
            this.titleIndex = 1;
            this.thumbnailActiveIndex = index;

            if (data.files && data.files.length > 0) {
                let tmpImageList = [];
                data.files.forEach((res) => {
                    this.videoTypeList.forEach((item) => {
                        if (res.url.indexOf('.' + item) != -1) {
                            res.typeStr = item;
                        }
                    });

                    tmpImageList.push(res.url);
                });
                this.tmpImgUrlList = data.files;
                this.imgUrlList = tmpImageList;
            }
        },
        setCurrentData(imgIndex) {
            imgIndex = String(imgIndex) != '0' && !imgIndex ? this.imgActiveIndex : imgIndex;
            this.suspendFlag = false;
            this.currentPreviewData = this.tmpImgUrlList[imgIndex];

            if (this.currentPreviewData && this.currentPreviewData.isOverHalfYear) {
                this.suspendFlag = true;
            }
        },
        switchNegotiate(data, index) {
            this.tmpAccCause = data ? data.accCause : '';
            this.tmpAccProcess = data ? data.accProcess : '';
            this.negotiateActiveIndex = index;
            this.caseMaterialsType = this.caseMaterialsParentType;
            if (this.negotiateInfo && this.negotiateInfo.length > 0) {
                let negotiate = data;
                if (negotiate && negotiate.materialsVos.length > 0) {
                    for (let index = 0; index < this.caseMaterialsParentType.length; index++) {
                        const element = this.caseMaterialsType[index];
                        element.files = [];
                        negotiate.materialsVos.forEach((val, key) => {
                            if (val.materialParentType === element.parentCode) {
                                val.url = val.materialPath ? this.OSSClient.signatureUrl(val.materialPath) : val.materialOriginalPath;

                                let imgType = ['.bmp', '.dib', '.pcp', '.dif', '.wmf', '.gif', '.jpg', '.tif', '.eps', '.psd', '.cdr', '.iff', '.tga', '.pcd', '.mpt', '.png', '.jpeg', '.psd', '.svg', '.ufo', '.dng', '.tga', '.eps', '.pcx', '.cdr'];
                                // 判断图片类型
                                let tmpUrlArr = val.url.split('?');
                                tmpUrlArr = tmpUrlArr[0];
                                let tmpLastIndex = tmpUrlArr.lastIndexOf('.');
                                let suffix = tmpLastIndex != -1 ? tmpUrlArr.slice(tmpLastIndex, tmpUrlArr.length).toLowerCase() : -1;
                                if (suffix.length > 6 && suffix.indexOf('%') != -1) {
                                    let tmpSuffix = suffix.split('%')[0];
                                    suffix = tmpSuffix ? (tmpSuffix == '.com' ? true : false) : '未识别';
                                }

                                // 图片后缀
                                val.isImg = Boolean(suffix != -1);
                                val.suffix = suffix;
                                element.files.push(val);
                                this.$set(this.caseMaterialsType, index, element);
                            }
                        });
                    }

                    // 获取存在图片的类型
                    let realityCaseTypeList = [];
                    this.showCaseListRealityLength = 0;
                    this.caseMaterialsType &&
                        this.caseMaterialsType.forEach((item, index) => {
                            if (item.files && item.files.length > 0) {
                                this.showCaseListRealityLength++;
                                realityCaseTypeList.push(item);
                            }
                        });
                    this.showCaseList = realityCaseTypeList;
                    if (this.showCaseList.length > 0) {
                        this.showCaseList.forEach((item, index) => {
                            if (item.files.length > 0 && index == 0) {
                                let tmpImageList = [];
                                item.files.forEach((res) => {
                                    tmpImageList.push(res.url);
                                });

                                this.tmpImgUrlList = item.files;
                                this.imgUrlList = tmpImageList;
                            }
                        });
                        this.liActiveIndex = 0;
                    }
                }
            }
        },
        checkImgUrl(url) {
            this.instance.get(url).then((res) => {
                if (!res) {
                    this.$emit('initOSSClient');
                }
            });
        },
        openCase(caseNo) {
            const routeUrl = this.$router.resolve({
                path: '/caseInquiry/index',
                query: { caseNo: caseNo, query: 'query' }
            });
            window.open(routeUrl.href, '_blank');
        },
        setTxtOverFlowStyle() {
            let ellipsisEls = document.querySelectorAll('.ellipsisJdPay');
            ellipsisEls.forEach((element, index) => {
                if (this.hasTextOverflowEllipsisAndOverflow(ellipsisEls[index])) {
                    element.classList.add('txtHoverStyle' + index);
                    this.txtHoverStyleObj['flag' + index] = true;
                }
            });
        },
        hasTextOverflowEllipsisAndOverflow(element) {
            // 获取元素的计算样式
            const computedStyle = window.getComputedStyle(element);

            // 检查是否设置了text-overflow: ellipsis
            if (computedStyle.textOverflow === 'ellipsis') {
                // 检查是否设置了overflow为非visible的值，并且white-space为nowrap
                if (computedStyle.overflow !== 'visible' && computedStyle.whiteSpace === 'nowrap') {
                    // 尝试使用scrollWidth（或offsetWidth）与clientWidth进行比较
                    // 如果scrollWidth（或offsetWidth）大于clientWidth，则说明文本溢出了
                    if (element.scrollWidth > element.clientWidth || element.offsetWidth > element.clientWidth) {
                        return true;
                    }
                }
            }

            return false;
        }
    },
    watch: {
        caseDetail(data) {
            if (data.caseNo) {
                let queryOssClientTime = setInterval(() => {
                    if (this.OSSClient) {
                        clearInterval(queryOssClientTime);
                        this.getNegotiateInfo();
                    } else {
                    }
                }, 1000);
            }
        },
        tmpOSSClient: {
            handler(val) {
                if (val) {
                    // console.log('jdPay');
                    // console.log(val);
                    this.OSSClient = val;
                }
            },
            immediate: true
        },
        elementShow: {
            handler(val) {
                if (val) {
                    this.setTxtOverFlowStyle();
                }
            },
            immediate: true
        }
    }
};
