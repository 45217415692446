
let cascCaderOptions = [];
let _this = null;
export default {
    props: {
        carPartDialog: {
            typeof: Boolean,
            default: false
        }
    },
    data() {
        return {
            dialogFlag: false,
            carPartFlag: false,
            carPartLoading: false,
            yearLoading: false,
            queryCarInfoFlag: false,
            carPartLoading: false,
            partPrice: null,
            searchCarForm: {
                cascader: '',
                year: ''
            },
            searchInfoForm: {
                oeOrPartName: ''
            },
            cascCaderProps: {
                lazy: true,
                lazyLoad(node, resolve) {
                    const { level } = node;

                    if (level <= 1) {
                        resolve(cascCaderOptions);
                    } else {
                        _this.getSeriesInfo(node.value).then((res) => {
                            resolve(res);
                        });
                    }
                }
            },
            resultCarList: [],
            provinceVal: '',
            yearOptions: [],
            structureList: [],
            carPartList: [],
            selectedParts: [],
            provinceOptions: [
                {
                    label: '北京',
                    value: 'marketPriceHb'
                },
                {
                    label: '天津',
                    value: 'marketPriceHb'
                },
                {
                    label: '河北',
                    value: 'marketPriceHb'
                },
                {
                    label: '山西',
                    value: 'marketPriceHb'
                },
                {
                    label: '内蒙古',
                    value: 'marketPriceHb'
                },
                {
                    label: '辽宁',
                    value: 'marketPriceDb'
                },
                {
                    label: '吉林',
                    value: 'marketPriceDb'
                },
                {
                    label: '黑龙江',
                    value: 'marketPriceDb'
                },
                {
                    label: '上海',
                    value: 'marketPriceHd'
                },
                {
                    label: '江苏',
                    value: 'marketPriceHd'
                },
                {
                    label: '浙江',
                    value: 'marketPriceHd'
                },
                {
                    label: '安徽',
                    value: 'marketPriceHd'
                },
                {
                    label: '重庆',
                    value: 'marketPriceXb'
                },
                {
                    label: '四川',
                    value: 'marketPriceXb'
                },
                {
                    label: '云南',
                    value: 'marketPriceXb'
                },
                {
                    label: '西藏',
                    value: 'marketPriceXb'
                },
                {
                    label: '陕西',
                    value: 'marketPriceXb'
                },
                {
                    label: '甘肃',
                    value: 'marketPriceXb'
                },
                {
                    label: '青海',
                    value: 'marketPriceXb'
                },
                {
                    label: '宁夏',
                    value: 'marketPriceXb'
                },
                {
                    label: '新疆',
                    value: 'marketPriceXb'
                },
                {
                    label: '福建',
                    value: 'marketPriceHn'
                },
                {
                    label: '江西',
                    value: 'marketPriceHn'
                },
                {
                    label: '湖北',
                    value: 'marketPriceHn'
                },
                {
                    label: '湖南',
                    value: 'marketPriceHn'
                },
                {
                    label: '广东',
                    value: 'marketPriceHn'
                },
                {
                    label: '深圳',
                    value: 'marketPriceHn'
                },
                {
                    label: '广西',
                    value: 'marketPriceHn'
                },
                {
                    label: '海南',
                    value: 'marketPriceHn'
                },
                {
                    label: '贵州',
                    value: 'marketPriceHn'
                }
            ]
        };
    },
    mounted() {
        _this = this;
        // 获取车辆品牌
        this.getBrandInfo();
    },
    methods: {
        // 品牌
        getBrandInfo() {
            cascCaderOptions = [];
            this.instance.get('/tpa/api/vehicle/getBrandInfo', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 200 && data.data) {
                    data.data.forEach((item) => {
                        item.value = item.amMainBrandId;
                        item.label = item.amMainBrandName;
                        if (item.amBrandList) {
                            item.amBrandList.forEach((childItem) => {
                                childItem.value = childItem.amBrandId;
                                childItem.label = childItem.amBrandName;
                            });
                            item.children = item.amBrandList;
                        }
                    });
                    cascCaderOptions = data.data;
                }
            });
        },
        // 车系
        getSeriesInfo(id) {
            return new Promise((resolve, reject) => {
                this.instance.get('/tpa/api/vehicle/getSeriesInfo', { params: { amBrandId: id } }).then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        data.data.forEach((item) => {
                            item.value = item.amSeriesId;
                            item.label = item.amSeriesName;
                            item.leaf = true;
                        });
                        resolve(data.data);
                    }
                });
            });
        },
        showCarDialog(showFlag) {
            if (showFlag) {
                this.searchCarForm = {
                    cascader: '',
                    year: ''
                };
                this.provinceVal = null;
                this.selectTableObj = {};
                this.partPrice = null;
                this.selectedParts = [];
                this.resultCarList = [];
                this.carPartFlag = false;
                this.queryCarInfoFlag = true;

                this.carVisible = true;
                this.switchGoodsDialog(!showFlag);
            } else {
                this.$emit('queryGoodsShow', { partType: 1, closeType: 1 });
            }
        },
        switchGoodsDialog(showFlag) {
            setTimeout(() => {
                this.goodsVisible = showFlag;
            }, 50);
        },
        handleSelectCost(val) {
            if (val && val.length > 0) {
                this.getSalesModelsInfo(val[val.length - 1]);
            } else {
                this.queryCarInfoFlag = true;
            }
        },
        // 车型
        getSalesModelsInfo(id) {
            this.yearOptions = [];
            this.carList = [];
            this.queryCarInfoFlag = true;
            this.yearLoading = true;
            this.searchCarForm.year = null;
            this.resultCarList = [];
            this.instance.get('/tpa/api/vehicle/getSalesModelsInfo', { params: { amSeriesId: id } }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    let yearArr = [];
                    data.data.forEach((item) => {
                        yearArr.push(item.amYear);
                    });
                    this.carList = data.data;
                    this.yearOptions = [...new Set(yearArr)];
                    this.yearOptions = this.yearOptions.sort((a, b) => b - a);
                    this.queryCarInfoFlag = false;
                    this.yearLoading = false;
                }
            });
        },
        searchCar() {
            if (!this.provinceVal) {
                this.$message.error('请选择省份！');
                return;
            }
            if (!this.searchCarForm.cascader) {
                this.$message.error('请选择车型！');
                return;
            }
            if (!this.searchCarForm.year) {
                this.$message.error('请选择年份！');
                return;
            }
            let tmpResult = [];
            this.carList.forEach((carItem) => {
                if (carItem.amYear == this.searchCarForm.year) {
                    tmpResult.push(carItem);
                }
            });
            this.resultCarList = tmpResult;
        },
        currentRowChange(val) {
            this.carPartFlag = true;

            this.searchInfoForm = {
                amVehicleId: val.amVehicleId,
                partGroupId: null,
                oeOrPartName: null,
                queryType: null
            };
            this.structureList = [];
            this.carPartList = [];
            this.selectedParts = [];
            this.selectTableObj = {};
            this.tmpMenuStr = '';
            this.activeMenuStr = '';
            this.partPrice = null;
            this.toggleSelection();

            this.getVehicleStructure(val.amVehicleId);
        },
        // 车辆结构
        getVehicleStructure(amModelId) {
            this.instance.get('/tpa/api/vehicle/getVehicleStructure', { params: { amModelId: amModelId } }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    let menusData = [];
                    data.data.forEach((val, key) => {
                        val.child = [];
                        if (val.partGroupLevel == 1) {
                            menusData.push(val);
                        }
                    });
                    data.data.forEach((val, key) => {
                        if (val.partGroupLevel == 2) {
                            menusData.forEach((value, index) => {
                                if (value.partGroupId == val.parentId) {
                                    value.child.push(val);
                                }
                            });
                        }
                    });
                    data.data.forEach((val, key) => {
                        if (val.partGroupLevel == 3) {
                            menusData.forEach((value, index) => {
                                for (let i = 0, len = value.child.length; i < len; i++) {
                                    if (value.child[i].partGroupId == val.parentId) {
                                        value.child[i].child.push(val);
                                    }
                                }
                            });
                        }
                    });

                    this.structureList = menusData;
                }
            });
        },
        goBackData() {
            this.carPartFlag = false;
            setTimeout(() => {
                this.structureList = [];
            }, 200);
        },
        searchCarPart(rowItem, searchFlag, activeStr) {
            if (searchFlag && !this.searchInfoForm.oeOrPartName) {
                this.$message.error('请输入零件名称/OE码！');
                return;
            }

            // 点击菜单
            this.activeMenuStr = activeStr;
            this.tmpMenuStr = '';

            this.getPartsList(rowItem);
        },
        // 零件列表
        getPartsList(rowItem) {
            if (rowItem) {
                this.searchInfoForm.partGroupId = rowItem.partGroupId;
                this.searchInfoForm.oeOrPartName = rowItem.partGroupName;
                this.searchInfoForm.queryType = 2;
            }
            this.carPartLoading = true;
            this.searchInfoForm.queryType = this.startsWithChinese(this.searchInfoForm.oeOrPartName) ? 2 : 1;
            this.instance.post('/tpa/api/vehicle/getPartsList', this.searchInfoForm).then((res) => {
                this.carPartLoading = false;
                let data = res.data;
                if (data.code == 200) {
                    let tmpVal = this.provinceVal.split('-')[1];
                    data.data &&
                        data.data.forEach((item) => {
                            item.tmpPrice = item.partsPriceDto[tmpVal];
                            item.guidePrice = item.partsPriceDto.guidePrice;
                        });
                    this.carPartList = data.data;
                    this.selectTableData();
                }
            });
        },
        // 回显选择数据
        selectTableData() {
            let tmpSlectObj = JSON.parse(JSON.stringify(this.selectTableObj));
            let selectData = tmpSlectObj[this.activeMenuStr];
            if (selectData) {
                let setSelectArr = [];
                this.carPartList.forEach((listItem) => {
                    selectData.forEach((dataItem) => {
                        if (listItem.oeId == dataItem.oeId) {
                            setSelectArr.push(listItem);
                        }
                    });
                });

                setTimeout(() => {
                    this.toggleSelection(setSelectArr);
                }, 200);
            }
        },
        startsWithChinese(str) {
            if (!str || str.length === 0) {
                return false;
            }

            const firstCharCode = str.charCodeAt(0);
            return firstCharCode >= 0x4e00 && firstCharCode <= 0x9fff;
        },
        handleSelectionChange(val) {
            if (!this.tmpMenuStr && val.length == 0) {
            } else {
                this.selectTableObj[this.activeMenuStr] = val;

                let rowArr = [];
                for (const key in this.selectTableObj) {
                    const subArray = this.selectTableObj[key];
                    const newSubArray = subArray.map((item) => item);
                    rowArr.push(...newSubArray);
                }
                this.selectedParts = rowArr;
            }

            this.calculatePrice();
        },
        closeDialog() {
            if (this.medicineProup) {
                this.medicineProup = false;
            } else {
                this.goodsVisible = false;
            }
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach((row) => {
                    this.$refs.vehicleTable.toggleRowSelection(row, true);
                });
            } else {
                this.$refs.vehicleTable.clearSelection();
            }
        },
        calculatePrice() {
            this.tmpMenuStr = JSON.parse(JSON.stringify(this.activeMenuStr));

            let tmpPrice = 0;
            this.selectedParts.forEach((priceItem) => {
                if (priceItem.tmpPrice) {
                    tmpPrice += Number(priceItem.tmpPrice) * 100000;
                }
            });

            if (tmpPrice >= 0) {
                this.partPrice = tmpPrice / 100000;
            }
        },
        usePartPrice() {
            if (this.selectedParts.length < 1) {
                this.$message.error('请选择配件！');
                return;
            }

            this.$emit('queryGoodsShow', { partType: 1, closeType: 2 });
            this.$emit('setLossUnitPrice', this.partPrice);
        }
    }
};
